/* DROPDOWN */

.dropdownButton{
    text-align: center;
}

.dropdown-toggle::after{
    margin-left:0;
}

.chi-siamo-dropdown .dropdown-toggle::after{
    transform: translateX(-13px);
}

.no-drop .dropdown-toggle::after{
    display:none;
}

.top-menu-container a,.top-menu-container Link, .dropdown-custom-components{
    color:black;
    text-decoration: none;
    padding: 30px 40px;
    transition: 0.6s;
}

.dropdown-custom-components{
    background-color:white;
    border-color:white;
    font-size:1em;
    font-family: 'Crimson Text', serif;
    text-transform: uppercase;
    padding-top:0;
}
.dropdown-toggle{
    transition: 1s;
}
.dropdown-toggle:hover,.btn-primary:focus{
    background-color: white;
    border-color:white;
    color:black;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: none !important;
}


.show>.btn-primary.dropdown-toggle, .btn-primary.focus, .btn-primary:active {
    color: black !important;
    background-color: white !important;
    border-color: white !important;
    box-shadow: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.top-menu-container{
    height: 450px;
    max-width: 1460px;
}

.top-logo{
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../assets/framare.jpg");
}

.top-menu-list{
    margin-top: 4%;
    /*font-size:1.3em;*/
    font-size: calc(10px + 1.2vmin);
    text-transform: uppercase;
}

.brush1:hover, .brush1.selected {
    background-image: url("../assets/paint-brush-stroke-4-23.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.brush2:hover, .brush2.selected {
    background-image: url("../assets/paint-brush-stroke-4-24.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.brush3:hover, .brush3.selected {
    background-image: url("../assets/paint-brush-stroke-4-11.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.top-social-container{
    margin-top:10px;
}

.facebook-top-logo{
    width:30px;
}

@media screen and (max-width: 1165px) {
    .top-menu-list {
        font-size: calc(7px + 1vmin);
    }
}

@media screen and (max-width: 768px) {
    .top-menu-container{
        height: 100vh;
    }
    .top-menu-list {
        font-size: calc(15px + 1vmin);
    }
    .colMenuLink{
    }
}
