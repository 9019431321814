#social{
    background-image: url("../assets/watercolor-background_3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.collegamenti-logo{
    width:25px;
    margin-right:5px;
}