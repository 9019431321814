.footerContainer{
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
    text-align: center;
    color: white;
    max-width:none;
    margin:0;
    padding-top:calc(40px + 1.5vmin);
    padding-bottom:calc(40px + 1.5vmin);
}
