#galleria{
    background-image: url("../assets/watercolor-background_3.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.carousel-gallery{
    margin-bottom: 10%;
}
