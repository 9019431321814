.App {
  text-align: center;
  overflow-x: hidden
}

.maxSize{
  max-width:none !important;
}

.pageContainer{
  font-size: calc(10px + 1.2vmin);
  padding-bottom: calc(50px + 1.5vmin);
  padding-top: calc(50px + 1.5vmin);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App{
  font-family: 'Crimson Text', serif;
  text-align: justify;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pl0 {
 padding-left: 0!important;
}

.pr0 {
  padding-right: 0!important;
}

.subtitle{
  text-transform: uppercase;
}

.title{
  text-align: center;
  font-size: calc(20px + 1.2vmin);
  text-transform: uppercase;
  font-family: 'Cormorant Unicase', serif;
  font-weight: 300;
}

.foto{
  padding-top: 10%;
  width:100%;
  height: 100%;
}
