#eventinotizie{
    background-image: url("../assets/wc4.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.event{
}

.separator{
    padding: 5%;
    width:100%;
    height: 80%;
  }